import { ThemeType } from '@share/enums';

const { REACT_APP_API_BASE_URL } = process.env;

const VectorLogo = undefined;

const defaultTheme = ThemeType.DARK;
const hasThemeSwitcher = false;
const hasNetworkSwitcher = false;
const hasWhatsappWidget = false;
const hasBanner = true;

const headerLogoSize = 84;
const HeaderLogo = `${REACT_APP_API_BASE_URL}/site/config/marketplaceLogo`;
const DarkHeaderLogo = HeaderLogo;
const FooterLogo = `${REACT_APP_API_BASE_URL}/site/config/marketplaceFooterLogo`;
const DarkFooterLogo = FooterLogo;
const footerLogoSize = 106;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
};
